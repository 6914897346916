.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: #ed1e22;
  border-color: #ed1e22;
}

// custom css by Mahedi
.pcoded-header .m-header {
  width: 230px;
  position: relative;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  background: white;
  border-bottom: 1px solid #ccc;
  box-shadow: 2px 2px 2px -2px #ea6d6d;
}

.pcoded-header .mobile-menu span,.pcoded-header .mobile-menu span::before, .pcoded-header .mobile-menu span::after {
  background: red !important;
}